<!--
 * @Author       : JiangChao
 * @Date         : 2023-04-18 10:15:38
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-02 09:42:32
 * @Description  : 
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <!-- <p style="font-size:14px;text-align:left">支付新台幣420元押金，即可立即使用</p> -->
            <div class="card">
                <div>訂閱中</div>
                <van-divider></van-divider>
                <van-cell title="訂閱時間：" style="text-align: left">
                </van-cell>
                <van-cell
                    :title="`${startExpireDate}至` + `${expireDate}`"
                    style="text-align: left"
                >
                </van-cell>
            </div>

            <div class="tip">
                <p>說明：</p>
                <p>
                    訂閱後請等待30秒或確認刷卡成功，再開始租借，可享訂閱期間不限時間與次數，無限制使用；綁定後每月會自動扣款續約，如不續用請取消訂閱。
                    若無法成功訂閱，請來電(02)2926-6681聯繫。
                </p>
            </div>

            <div class="login-way">
                <div class="sms-way" @click="showDialog">取消綁定</div>
            </div>
            <van-dialog
                v-model="show"
                title="提示"
                message="取消綁定後至優惠時間結束將無法免費租借"
                confirmButtonText="確認"
                cancelButtonText="取消"
                show-cancel-button
                @confirm="confirm"
                @cancel="show == false"
            >
            </van-dialog>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";

export default {
    name: "",
    data() {
        return {
            startExpireDate: "",
            expireDate: "",
            show: false,
        };
    },
    methods: {
        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
        showDialog() {
            this.show = true;
        },
        async confirm() {
            try {
                const res = await this.$axios.get(`/tapPay/subscribe/cancel`);
                if (res.data.success) {
                    Toast("解綁成功");
                    this.$router.push("/map");
                } else {
                    Toast("解綁失敗"+res.data.msg);
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        this.token = window.$cookies.get("token");

        if (this.token) {
            this.$axios.defaults.headers.common["token"] = this.token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        this.expireDate = localStorage.getItem("expireDate");
        if (this.expireDate == "null") {
            this.expireDate = "";
        }
        this.startExpireDate = localStorage.getItem("startExpireDate");
        if (this.startExpireDate == "null") {
            this.startExpireDate = "";
        }
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #f8f8f8;
    text-align: center;
    padding: 20px;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }
    .btn {
        position: fixed;
        bottom: 0;
    }
    .read-moudle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        .clause {
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 12px;
            margin-left: 10px;
        }
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
